import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NewsInternalArticle = ({ data, location }) => {
  const { data: article } = data.allPrismicNewsInternalArticle.edges[0].node

  return (
    <Layout>
      <SEO title={article.title ? article.title : 'news'} location={location} />

      <div className="pb-5 pt-32 lg:pt-72">
        <div className="grid">
          <div className="grid__half--primary">
            <h1 className="type-lg lg:type-2xl text-white mb-2">
              {article.title}
            </h1>

            <small className="type-sm flex-1 text-right">{article.date}</small>

            <div className="w-4/5 my-12 lg:mt-24">
            { article.image.localFile != null &&
              <Img
                fluid={article.image.localFile.childImageSharp.fluid}
                alt={article.title}
              /> }
            </div>
          </div>
          <div className="grid__half--secondary">
            {article.lead && (
              <div
                className="type-lg pt-4"
                dangerouslySetInnerHTML={{
                  __html: article.lead.html,
                }}
              />
            )}
            {article.body && (
              <div
                className="rte type-lg pt-4"
                dangerouslySetInnerHTML={{
                  __html: article.body.html,
                }}
              />
            )}
          </div>
        </div>
        <div className="grid mt-24 mb-12">
          <div className="grid__half--secondary">
            <Link className="link type-lg" to="/news">
              Back to News
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const articleQuery = graphql`
  query ArticleByUid($uid: String!) {
    allPrismicNewsInternalArticle(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          data {
            title
            date(formatString: "D MMMM YY")
            lead {
              text
              html
            }
            body {
              text
              html
            }
            image {
              url
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default NewsInternalArticle
